import {
  alt,
  apply,
  buildLexer,
  expectEOF,
  expectSingleResult,
  kmid,
  rep_sc as repSc,
  rule,
  seq,
  tok,
} from 'typescript-parsec';

import assertUnreachable from './assertUnreachable';

export type Filter = AndFilter | BooleanFilter | DatetimeFilter | FreeFilter | NotFilter | OrFilter | StringFilter;

export interface StringFilter {
  kind: 'string';
  type: string;
  value: string;
}

export interface FreeFilter {
  kind: 'free';
  value: string;
}

export interface DatetimeFilter {
  kind: 'range';
  type: string;
  d1: string;
  d2: string;
}

export interface BooleanFilter {
  kind: 'bool';
  type: string;
  value: boolean;
}

export interface OrFilter {
  kind: 'or';
  value: Array<Filter>;
}

export interface AndFilter {
  kind: 'and';
  value: Array<Filter>;
}

export interface NotFilter {
  kind: 'not';
  value: Filter;
}

enum TokenKind {
  Whitespace,
  DateRangeKeyword,
  StringKeyword,
  BoolKeyword,
  String,
  QuotedString,
  Datetime,
  Or,
  And,
  Not,
  Colon,
  Comma,
  True,
  False,
  LPar,
  RPar,
  Dash,
  Any,
}

const lexer = buildLexer([
  [false, /^\s+/gu, TokenKind.Whitespace],
  [true, /^:/g, TokenKind.Colon],
  [true, /^,/g, TokenKind.Comma],
  [true, /^[(]/g, TokenKind.LPar],
  [true, /^[)]/g, TokenKind.RPar],
  [true, /^-/g, TokenKind.Dash],
  [true, /^(declared|started|resolved|ended|created|event)\b/gi, TokenKind.DateRangeKeyword],
  [
    true,
    /^(title|status|severity|label|type|kind|role|userid|user.email|integrationid|body|reaction|tag|field|summary|relevance)\b/gi,
    TokenKind.StringKeyword,
  ],
  [true, /^or\b/gi, TokenKind.Or],
  [true, /^not\b/gi, TokenKind.Not],
  [true, /^and\b/gi, TokenKind.And],
  [true, /^true\b/gi, TokenKind.True],
  [true, /^false\b/gi, TokenKind.False],
  [true, /^(isdrill|isimmutable)\b/gi, TokenKind.BoolKeyword],
  [true, /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,})?(Z|[+-]\d{2}:\d{2})\b/gi, TokenKind.Datetime],
  [true, /^('[^']*'|"[^"]*")/giu, TokenKind.QuotedString],
  [true, /^\w+\b/giu, TokenKind.String],
  [true, /^\W/giu, TokenKind.Any],
]);

const FULL_TERM = rule<TokenKind, Filter>();
const SIMPLE_TERM = rule<TokenKind, Filter>();

const parseQuotedString = apply(tok(TokenKind.QuotedString), (value) =>
  value.text.slice(1, -1).replace(/\\(.)/g, '$1')
);

const parseStringRule = alt(
  apply(tok(TokenKind.String), (x) => x.text),
  apply(tok(TokenKind.DateRangeKeyword), (x) => x.text),
  apply(tok(TokenKind.Or), (x) => x.text),
  apply(tok(TokenKind.And), (x) => x.text),
  apply(tok(TokenKind.BoolKeyword), (x) => x.text),
  apply(tok(TokenKind.Datetime), (x) => x.text),
  apply(tok(TokenKind.True), (x) => x.text),
  apply(tok(TokenKind.False), (x) => x.text),
  apply(tok(TokenKind.StringKeyword), (x) => x.text),
  parseQuotedString
);

const parseStringFilter = apply(
  seq(tok(TokenKind.StringKeyword), tok(TokenKind.Colon), parseStringRule),
  ([keyword, , value]) => ({ kind: 'string', type: keyword.text.toLowerCase(), value }) as StringFilter
);

const parseDatetimeFilter = apply(
  seq(
    tok(TokenKind.DateRangeKeyword),
    tok(TokenKind.Colon),
    tok(TokenKind.Datetime),
    tok(TokenKind.Comma),
    tok(TokenKind.Datetime)
  ),
  ([type, , value1, , value2]) =>
    ({
      kind: 'range',
      type: type.text.toLowerCase(),
      d1: value1.text,
      d2: value2.text,
    }) as DatetimeFilter
);

const parseBooleanFilter = apply(
  seq(tok(TokenKind.BoolKeyword), tok(TokenKind.Colon), alt(tok(TokenKind.True), tok(TokenKind.False))),
  ([type, , value]) =>
    ({
      kind: 'bool',
      type: type.text.toLowerCase(),
      value: value.text.toLowerCase() === 'true',
    }) as BooleanFilter
);

const parseFullNot = apply(
  seq(tok(TokenKind.Not), kmid(tok(TokenKind.LPar), FULL_TERM, tok(TokenKind.RPar))),
  ([, value]) => ({ kind: 'not', value }) as NotFilter
);

const parseNotShortcut = apply(seq(tok(TokenKind.Dash), SIMPLE_TERM), ([x, value]) => {
  if (value.kind === 'string' || value.kind === 'bool' || value.kind === 'range') {
    return { kind: 'not', value } as NotFilter;
  }
  if (value.kind === 'not') {
    return value.value;
  }
  if (value.kind === 'and' || value.kind === 'or') {
    return { kind: 'not', value } as NotFilter;
  }
  return { kind: 'free', value: `-${x.text}` } as FreeFilter;
});

const parseNotFilter = alt(parseFullNot, parseNotShortcut);

const parseOr = apply(
  seq(tok(TokenKind.Or), kmid(tok(TokenKind.LPar), repSc(FULL_TERM), tok(TokenKind.RPar))),
  ([, value]) =>
    ({
      kind: 'or',
      value,
    }) as OrFilter
);

const parseAnd = apply(
  seq(tok(TokenKind.And), kmid(tok(TokenKind.LPar), repSc(FULL_TERM), tok(TokenKind.RPar))),
  ([, value]) =>
    ({
      kind: 'and',
      value,
    }) as AndFilter
);

const parseAny = apply(repSc(tok(TokenKind.Any)), (x) => x.map((xx) => xx.text).join(''));

const parseFreeText = apply(
  alt(
    apply(tok(TokenKind.String), (x) => x.text),
    apply(tok(TokenKind.Comma), (x) => x.text),
    apply(tok(TokenKind.Colon), (x) => x.text),
    apply(tok(TokenKind.Dash), (x) => x.text),
    apply(tok(TokenKind.True), (x) => x.text),
    apply(tok(TokenKind.False), (x) => x.text),
    apply(tok(TokenKind.False), (x) => x.text),
    apply(tok(TokenKind.QuotedString), (x) => x.text),
    apply(tok(TokenKind.LPar), (x) => x.text),
    apply(tok(TokenKind.RPar), (x) => x.text),
    parseAny
  ),
  (value) =>
    ({
      kind: 'free',
      value,
    }) as FreeFilter
);

SIMPLE_TERM.setPattern(alt(parseStringFilter, parseDatetimeFilter, parseBooleanFilter));
FULL_TERM.setPattern(alt(SIMPLE_TERM, parseNotFilter, parseOr, parseAnd));

/// We also allow free text at the top level
const parseFilter = repSc(alt(FULL_TERM, parseFreeText));

export function parseQuery(query: string): ReadonlyArray<Filter> {
  const lexed = lexer.parse(query);
  const filters = expectSingleResult(expectEOF(parseFilter.parse(lexed)));
  return filters;
}

const specialCharsRegexp = /^[a-zA-Z0-9]+$/i;
function printStringFilter(filter: StringFilter): string {
  if (specialCharsRegexp.test(filter.value ?? '')) {
    // no need for quotes
    return `${filter.type}:${filter.value}`;
  }
  return `${filter.type}:'${(filter.value ?? '').replace("'", "\\'")}'`;
}

function printDatetimeFilter(filter: DatetimeFilter): string {
  return `${filter.type}:${filter.d1},${filter.d2}`;
}

function printBooleanFilter(filter: BooleanFilter): string {
  return `${filter.type}:${filter.value ? 'true' : 'false'}`;
}

function printOrFilter(filter: OrFilter): string {
  const subFilters = filter.value.map(prettyPrintFilter).join(' ');
  return `or(${subFilters})`;
}

function printAndFilter(filter: AndFilter): string {
  const subFilters = filter.value.map(prettyPrintFilter).join(' ');
  return `and(${subFilters})`;
}

function printNotFilter(filter: NotFilter): string {
  const subFilter = prettyPrintFilter(filter.value);
  return `-${subFilter}`;
}

function printFreeFilter(filter: FreeFilter): string {
  return filter.value;
}

function prettyPrintFilter(filter: Filter): string {
  switch (filter.kind) {
    case 'string':
      return printStringFilter(filter);
    case 'range':
      return printDatetimeFilter(filter);
    case 'bool':
      return printBooleanFilter(filter);
    case 'or':
      return printOrFilter(filter);
    case 'and':
      return printAndFilter(filter);
    case 'not':
      return printNotFilter(filter);
    case 'free':
      return printFreeFilter(filter);
    default:
      return assertUnreachable(filter);
  }
}

export function filtersAsString(filters: ReadonlyArray<Filter>): string {
  return filters.map(prettyPrintFilter).join(' ');
}
