import { getOrd as getArrayOrd } from 'fp-ts/Array';
import { Ord as ordBoolean } from 'fp-ts/boolean';
import { type Eq, fromEquals } from 'fp-ts/Eq';
import { type Lazy, pipe } from 'fp-ts/function';
import { contramap, fromCompare, type Ord, tuple as getTupleOrd } from 'fp-ts/Ord';
import { Ord as ordString } from 'fp-ts/string';

import assertUnreachable from './assertUnreachable';
import type {
  AndFilter,
  BooleanFilter,
  DatetimeFilter,
  Filter,
  FreeFilter,
  NotFilter,
  OrFilter,
  StringFilter,
} from './queryParser';

// Eq instances for sub-types
const stringFilterEq: Eq<StringFilter> = fromEquals(
  (a, b) => a.kind === b.kind && a.type === b.type && a.value === b.value
);
const freeFilterEq: Eq<FreeFilter> = fromEquals((a, b) => a.kind === b.kind && a.value === b.value);
const datetimeFilterEq: Eq<DatetimeFilter> = fromEquals(
  (a, b) => a.kind === b.kind && a.type === b.type && a.d1 === b.d1 && a.d2 === b.d2
);
const booleanFilterEq: Eq<BooleanFilter> = fromEquals(
  (a, b) => a.kind === b.kind && a.type === b.type && a.value === b.value
);
const orFilterEq: Eq<OrFilter> = fromEquals(
  (a, b) =>
    a.kind === b.kind &&
    a.value.length === b.value.length &&
    a.value.every((x, i) => eqFilter.equals(x, b.value[i] ?? ({} as Filter)))
);
const andFilterEq: Eq<AndFilter> = fromEquals(
  (a, b) =>
    a.kind === b.kind &&
    a.value.length === b.value.length &&
    a.value.every((x, i) => eqFilter.equals(x, b.value[i] ?? ({} as Filter)))
);
const notFilterEq: Eq<NotFilter> = fromEquals((a, b) => a.kind === b.kind && eqFilter.equals(a.value, b.value));

/**
 * An Eq instance for determining deep equality of Filter objects.
 *
 * This Eq instance allows you to check the deep equality of Filter objects. It takes
 * into account the kind of filter as well as the properties of each specific filter
 * type, ensuring that two Filter objects are considered equal if and only if they have
 * the same kind and their properties are equal.
 * You probably want to sort with ordFilter, first, if you are using this for cache keys.
 *
 * Usage:
 * ```
 * import { equals } from 'fp-ts/Eq';
 *
 * const filter1: Filter = ...;
 * const filter2: Filter = ...;
 * const isEqual = equals(filterEqInstance)(filter1, filter2);
 * ```
 */
export const eqFilter: Eq<Filter> = fromEquals((a, b) => {
  switch (a.kind) {
    case 'string':
      return stringFilterEq.equals(a, b as StringFilter);
    case 'free':
      return freeFilterEq.equals(a, b as FreeFilter);
    case 'range':
      return datetimeFilterEq.equals(a, b as DatetimeFilter);
    case 'bool':
      return booleanFilterEq.equals(a, b as BooleanFilter);
    case 'or':
      return orFilterEq.equals(a, b as OrFilter);
    case 'and':
      return andFilterEq.equals(a, b as AndFilter);
    case 'not':
      return notFilterEq.equals(a, b as NotFilter);
    default:
      return assertUnreachable(a);
  }
});

const stringFilterOrd: Ord<StringFilter> = pipe(
  getTupleOrd(ordString, ordString),
  contramap((f: StringFilter) => [f.type, f.value] as const)
);
const freeFilterOrd: Ord<FreeFilter> = pipe(
  ordString,
  contramap((f: FreeFilter) => f.value)
);
const datetimeFilterOrd: Ord<DatetimeFilter> = pipe(
  getTupleOrd(ordString, ordString, ordString),
  contramap((f: DatetimeFilter) => [f.type, f.d1, f.d2] as const)
);
const booleanFilterOrd: Ord<BooleanFilter> = pipe(
  getTupleOrd(ordString, ordBoolean),
  contramap((f: BooleanFilter) => [f.type, f.value] as const)
);
const lazyFilterOrd: Lazy<Ord<Filter>> = () =>
  fromCompare((a, b) => {
    if (a.kind !== b.kind) {
      return ordString.compare(a.kind, b.kind);
    }

    switch (a.kind) {
      case 'string':
        return stringFilterOrd.compare(a, b as StringFilter);
      case 'free':
        return freeFilterOrd.compare(a, b as FreeFilter);
      case 'range':
        return datetimeFilterOrd.compare(a, b as DatetimeFilter);
      case 'bool':
        return booleanFilterOrd.compare(a, b as BooleanFilter);
      case 'or':
        return getArrayOrd(lazyFilterOrd()).compare(a.value, (b as OrFilter).value);
      case 'and':
        return getArrayOrd(lazyFilterOrd()).compare(a.value, (b as AndFilter).value);
      case 'not':
        return lazyFilterOrd().compare(a.value, (b as NotFilter).value);
      default:
        return assertUnreachable(a);
    }
  });

/**
 * An Ord instance for comparing Filter objects.
 *
 * This Ord instance allows you to compare Filter objects for sorting and other
 * ordering-related operations. It takes into account the kind of filter as well
 * as the properties of each specific filter type.
 *
 * The ordering is defined as follows:
 * 1. Filters are first ordered by their kind in alphabetical order.
 * 2. Within each kind, the filters are ordered based on their properties.
 *
 * Note that this assumes that the order of elements in 'value' arrays of Filter[], OrFilter
 * and AndFilter matters. If the order doesn't matter, you may need to sort the
 * arrays before comparing them.
 *
 * Also note that this should not be used for user-facing queries, but is useful
 * for canonicalization prior to deduplication or cache keys.
 *
 * Usage:
 * ```
 * import { sort } from 'fp-ts/Array';
 *
 * const filters: Filter[] = [...];
 * const sortedFilters = sort(filterOrdInstance)(filters);
 * ```
 **/
export const ordFilter = lazyFilterOrd();
