import type { Client, ClientResponse, APIResponse, Json, JsonObject, SimpleError } from '@grafana-irm/core';
import { type BackendSrvRequest, type FetchResponse, getBackendSrv } from '@grafana/runtime';
import type { DataSourceJsonData } from '@grafana/schema';

import { lastValueFrom } from 'rxjs';
import { z } from 'zod';

import {
  type OnCallAlertGroup,
  OnCallAlertGroupSchema,
  type OnCallTeam,
  type OnCallUser,
  type OnCallUserPage,
  OnCallUserPageSchema,
  OnCallUserSchema,
  OncallTeamObjectSchema,
} from 'components/Responders/types';

export const DS_NAME = 'Grafana Incident';
export const INSIGHTS_DATASOURCE_TYPE = 'grafana-incident-datasource';
export const DATASOURCE_SA_NAME = 'grafanacloud-incident-datasource-sa-viewer';
export const DATASOURCE_SA_TOKEN_NAME = 'grafanacloud-incident-datasource-sa-viewer-token';

export interface ServiceAccount {
  id: number;
  label: string;
  avatarUrl: string;
  login: string;
  email: string;
  name: string;
  displayName: string;
  orgId?: number;
}

/// Initiate an incident request
export async function incidentApi<T extends JsonObject>(
  url: string,
  options: Omit<BackendSrvRequest, 'url'>
): Promise<FetchResponse<T> | undefined> {
  return lastValueFrom(getBackendSrv().fetch<T>({ ...options, url: `/api/plugins/grafana-incident-app/${url}` }));
}

/// Initiate an oncall request
export async function onCallApi<T extends JsonObject>(
  url: string,
  options?: Omit<BackendSrvRequest, 'url'>
): Promise<FetchResponse<T>> {
  return lastValueFrom(
    getBackendSrv().fetch<T>({
      ...options,
      url: `/api/plugin-proxy/grafana-oncall-app/api/internal/v1/${url}`,
    })
  );
}

/// Initiate a grafana request
export async function getInstancePlugins<T extends JsonObject>(
  options?: Omit<BackendSrvRequest, 'url'>
): Promise<FetchResponse<T> | undefined> {
  return lastValueFrom(getBackendSrv().fetch<T>({ ...options, url: '/api/plugins' }));
}

/// Initiate a grafana request to resources
export async function incidentResourceApi<T extends JsonObject>(
  url: string,
  options: Omit<BackendSrvRequest, 'url'>
): Promise<FetchResponse<T> | undefined> {
  return incidentApi<T>(`resources/api/${url}`, options);
}

/// The `GrafanaInternalApiClient` class is responsible for interacting with the Grafana Incident API.
/// Importantly, it implements the `Client` interface and provides methods to send requests and handle responses.
/// The class handles setting up the necessary headers for requests and processes the responses to determine success or failure.
/// It also includes error reporting functionality.
export class GrafanaInternalApiClient implements Client {
  readonly #headers: Headers;

  public constructor() {
    this.#headers = new Headers();
    this.#headers.set('Accept', 'application/json');
    this.#headers.set('Content-Type', 'application/json');
  }

  public async fetch(path: string, payload: Json): Promise<ClientResponse> {
    const res = await incidentResourceApi(path, { method: 'POST', headers: this.#headers, data: payload });
    if (res?.ok === true) {
      return { success: true, payload: res.data };
    }
    return {
      success: false,
      error: {
        status: 502,
        statusText: 'Internal Server Error',
        message: `Failed to proxy to internal system`,
      },
    };
  }

  public reportError(error: unknown): void {
    console.error('Grafana API Client Error: ', error);
  }
}

export async function getOncallAlertGroup(alertGroupId: string | undefined): Promise<APIResponse<OnCallAlertGroup>> {
  if (alertGroupId == null) {
    return {
      success: false,
      error: {
        message: 'No alert group id provided',
        status: 400,
        statusText: 'Bad Request',
      } as SimpleError,
    };
  }
  const params = new URLSearchParams({
    short: 'false',
  });
  const fetchResult = await onCallApi<JsonObject>(`alertgroups/${alertGroupId}/?${params.toString()}`);
  if (!fetchResult.ok) {
    return {
      success: false,
      error: {
        message: fetchResult.statusText,
        status: fetchResult.status,
        statusText: fetchResult.statusText,
      } as SimpleError,
    };
  }
  return OnCallAlertGroupSchema.safeParseAsync(fetchResult.data) as Promise<APIResponse<OnCallAlertGroup>>;
}

/// This API returns a raw array, it looks like, no pagination available
export async function getOncallTeams(qs: string, onlyNotifiableTeams?: boolean): Promise<APIResponse<OnCallTeam[]>> {
  const params = new URLSearchParams({
    search: qs,
    short: 'false',
    // eslint-disable-next-line camelcase
    only_include_notifiable_teams: String(onlyNotifiableTeams ?? ''),
  });
  const fetchResult = await onCallApi<JsonObject>(`teams/?${params.toString()}`);
  if (!fetchResult.ok) {
    return {
      success: false,
      error: {
        message: fetchResult.statusText,
        status: fetchResult.status,
        statusText: fetchResult.statusText,
      } as SimpleError,
    };
  }
  return OncallTeamObjectSchema.array().safeParse(fetchResult.data);
}

export async function getOncallUsers(qs: string, isOncall: 'all'): Promise<APIResponse<OnCallUser[]>>;
export async function getOncallUsers(
  qs: string,
  isOncall: 'false' | 'true',
  page: number
): Promise<APIResponse<OnCallUserPage>>;
export async function getOncallUsers(
  qs: string,
  isOncall: 'all' | 'false' | 'true',
  page?: number
): Promise<APIResponse<OnCallUser[] | OnCallUserPage>> {
  if (isOncall === 'false' || isOncall === 'true') {
    const pagedParams = new URLSearchParams({
      short: 'false',
      // eslint-disable-next-line camelcase
      is_currently_oncall: isOncall,
      page: String(page ?? 1),
      search: qs,
    });
    const fetchPagedResult = await onCallApi<JsonObject>(`users/?${pagedParams.toString()}`);
    if (!fetchPagedResult.ok) {
      return {
        success: false,
        error: {
          message: fetchPagedResult.statusText,
          status: fetchPagedResult.status,
          statusText: fetchPagedResult.statusText,
        } as SimpleError,
      };
    }
    return OnCallUserPageSchema.safeParse(fetchPagedResult.data);
  }
  const params = new URLSearchParams({
    short: 'false',
    // eslint-disable-next-line camelcase
    is_currently_oncall: 'all',
    search: qs,
  });
  const fetchResult = await onCallApi<JsonObject>(`users/?${params.toString()}`);
  if (!fetchResult.ok) {
    return {
      success: false,
      error: {
        message: fetchResult.statusText,
        status: fetchResult.status,
        statusText: fetchResult.statusText,
      } as SimpleError,
    };
  }
  return OnCallUserSchema.array().safeParse(fetchResult.data);
}

export async function createDirectPage(
  message: string,
  incidentID: string,
  teamId: number | string
): Promise<APIResponse<OnCallAlertGroup>>;
export async function createDirectPage(
  message: string,
  incidentID: string,
  users: ReadonlyArray<{ important: boolean; id: string }>
): Promise<APIResponse<OnCallAlertGroup>>;
export async function createDirectPage(
  message: string,
  incidentID: string,
  teamOrUsers: ReadonlyArray<{ important: boolean; id: string }> | number | string
): Promise<APIResponse<OnCallAlertGroup>> {
  let payload: string;
  const sourceURL = window.location.href;
  // eslint-disable-next-line camelcase
  const commonPayload = { message, grafana_incident_id: incidentID, source_url: sourceURL };
  if (typeof teamOrUsers === 'string') {
    payload = JSON.stringify({ ...commonPayload, team: teamOrUsers, users: [] });
  } else {
    payload = JSON.stringify({ ...commonPayload, team: null, users: teamOrUsers });
  }

  const fetchResult = await onCallApi('direct_paging', { method: 'POST', data: payload });
  if (!fetchResult.ok) {
    return {
      success: false,
      error: {
        message: fetchResult.statusText,
        status: fetchResult.status,
        statusText: fetchResult.statusText,
      } as SimpleError,
    };
  }
  // eslint-disable-next-line camelcase
  const res = z.object({ alert_group_id: z.string() }).safeParse(fetchResult.data);
  if (res.success) {
    const result = await onCallApi(`alertgroups/${res.data.alert_group_id}/`, { method: 'GET' });
    if (!result.ok) {
      return {
        success: false,
        error: {
          message: result.statusText,
          status: result.status,
          statusText: result.statusText,
        } as SimpleError,
      };
    }
    const parsed = await OnCallAlertGroupSchema.safeParseAsync(result.data);
    if (parsed.success) {
      return { success: true, data: parsed.data };
    }
    return { success: false, error: parsed.error };
  }
  return { success: false, error: res.error };
}

/// Updating is limited to adding users to an existing direct page id
export async function addUsersToDirectPage(
  existingId: string,
  users: ReadonlyArray<{ important: boolean; id: string }>
): Promise<APIResponse<string>> {
  // eslint-disable-next-line camelcase
  const payload = JSON.stringify({ alert_group_id: existingId, team: null, users });
  const fetchResult = await onCallApi('direct_paging', { method: 'POST', data: payload });
  if (!fetchResult.ok) {
    return {
      success: false,
      error: {
        message: fetchResult.statusText,
        status: fetchResult.status,
        statusText: fetchResult.statusText,
      } as SimpleError,
    };
  }
  // eslint-disable-next-line camelcase
  const res = z.object({ alert_group_id: z.string() }).safeParse(fetchResult.data);
  if (res.success) {
    return { success: true, data: res.data.alert_group_id };
  }
  return { success: false, error: res.error };
}

/// Acknowledge an alert group for a user
export async function resolveAlertGroup(existingId: string, unresolve = false): Promise<APIResponse<OnCallAlertGroup>> {
  const fetchResult = await onCallApi(`alertgroups/${existingId}/${unresolve ? 'un' : ''}resolve/`, { method: 'POST' });
  if (!fetchResult.ok) {
    return {
      success: false,
      error: {
        message: fetchResult.statusText,
        status: fetchResult.status,
        statusText: fetchResult.statusText,
      } as SimpleError,
    };
  }
  const res = OnCallAlertGroupSchema.safeParse(fetchResult.data);
  if (res.success) {
    return { success: true, data: res.data };
  }
  return { success: false, error: res.error };
}

/// Acknowledge an alert group for a user
export async function acknowledgeAlertGroup(existingId: string): Promise<APIResponse<OnCallAlertGroup>> {
  const fetchResult = await onCallApi(`alertgroups/${existingId}/acknowledge/`, { method: 'POST' });
  if (!fetchResult.ok) {
    return {
      success: false,
      error: {
        message: fetchResult.statusText,
        status: fetchResult.status,
        statusText: fetchResult.statusText,
      } as SimpleError,
    };
  }

  const res = OnCallAlertGroupSchema.safeParse(fetchResult.data);
  if (res.success) {
    return { success: true, data: res.data };
  }
  return { success: false, error: res.error };
}

/// Remove a direct page, which will clean up the which will clean up all paging to that user/team
export async function removeAlertGroup(existingId: string): Promise<void> {
  const fetchResult = await onCallApi(`alertgroups/${existingId}/`, { method: 'DELETE' });
  if (!fetchResult.ok) {
    throw fetchResult.data;
  }
}

/// Remove a user from an existing alert group (direct page)
export async function removeUserFromDirectPage(existingId: string, userId: string): Promise<void> {
  // eslint-disable-next-line camelcase
  const payload = JSON.stringify({ user_id: userId });
  const fetchResult = await onCallApi(`alertgroups/${existingId}/unpage_user`, { method: 'POST', data: payload });
  if (!fetchResult.ok) {
    throw fetchResult.data;
  }
}

/// Remove a user from an existing alert group (direct page)
export async function getOnCallTeam(teamId: string): Promise<OnCallTeam> {
  const fetchResult = await onCallApi(`teams/${teamId}`);
  if (!fetchResult.ok) {
    throw fetchResult.data;
  }
  const result = OncallTeamObjectSchema.safeParse(fetchResult.data);
  if (result.success) {
    return result.data;
  }
  throw result.error;
}

export interface ApiKey {
  id: number;
  name: string;
  key: string;
  role: string;
}

export async function getApiKeys(): Promise<ApiKey[]> {
  return getBackendSrv().get(`/api/auth/keys`);
}

interface CreateApiKeyResult {
  name: string;
  key: string;
}

export async function createApiKey(name: string, role: string, secondsToLive = 0): Promise<CreateApiKeyResult> {
  return getBackendSrv().post(`/api/auth/keys`, { name, role, secondsToLive });
}

export async function deleteApiKey(id: number): Promise<void> {
  return getBackendSrv().delete(`/api/auth/keys/${id}`);
}

export async function deleteServiceAccount(serviceAccountId: number): Promise<void> {
  return getBackendSrv().delete(`/api/serviceaccounts/${serviceAccountId}`);
}

export async function createServiceAccountToken(
  serviceAccountId: number,
  name: string,
  role: string
): Promise<ServiceAccountToken> {
  return getBackendSrv().post(`/api/serviceaccounts/${serviceAccountId}/tokens`, {
    name,
    role,
  });
}

export async function searchTokensByServiceAccountID(serviceAccountID: number): Promise<Record<string, unknown>[]> {
  return getBackendSrv().get<Record<string, unknown>[]>(`/api/serviceaccounts/${serviceAccountID}/tokens`);
}

export async function createServiceAccount(name: string, role: string): Promise<ServiceAccount> {
  return getBackendSrv().post('/api/serviceaccounts', {
    name: name,
    role: role,
    isDisabled: false,
  });
}

interface ServiceAccountsResponse {
  serviceAccounts: ServiceAccount[];
}

export async function searchServiceAccountByName(name: string): Promise<ServiceAccount | undefined> {
  const { serviceAccounts = [] } = await getBackendSrv().get<ServiceAccountsResponse>(
    `/api/serviceaccounts/search?query=${name}`
  );

  return serviceAccounts.find((serviceAccount) => serviceAccount.name === name);
}

export async function registerAppPluginInstallationWithIncident(): Promise<Record<string, unknown>[]> {
  return getBackendSrv().post(`/api/plugins/grafana-incident-app/resources/register`);
}

interface BackendPluginStatusResult {
  orgID: string;
  isEnabled: boolean;
  isSetup: boolean;
  isHealthy: boolean;
}

export async function statusAppPluginInstallationWithIncident(): Promise<BackendPluginStatusResult | null> {
  return getBackendSrv().get(`/api/plugins/grafana-incident-app/resources/status`);
}

export async function insightsDsExists(): Promise<boolean> {
  const allDatasources = await getBackendSrv().get('api/datasources');
  return Boolean(allDatasources.some((x: { type?: string }) => x.type === INSIGHTS_DATASOURCE_TYPE));
}

export async function createInsightsDatasource(payload: DatasourcePayload): Promise<IncidentDataSourceOptions | null> {
  return getBackendSrv().post('api/datasources', {
    name: DS_NAME,
    type: 'grafana-incident-datasource',
    access: 'proxy',
    isDefault: false,
    url: payload.apiHost,
    readyOnly: true,
    jsonData: {},
    secureJsonData: {
      accessToken: payload.accessToken,
    },
  });
}

interface ServiceAccountToken {
  name: string;
  id: number;
  key: string;
}
interface IncidentDataSourceOptions extends DataSourceJsonData {
  url?: string;
  variables?: unknown;
  annotations?: unknown;
}

interface DatasourcePayload {
  accessToken: string;
  apiHost: string;
}
